import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../context/LoginContext";


const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signInMail, user } = useLogin();
  const navigate = useNavigate();

  // console.log("USER:",user)

  useEffect(() => {
    if (user != null) {
      navigate("/home");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await signInMail({ email, password });
  };

  return (
    <>
      <nav className="bg-primary w-full justify-center">
        <div className="flex items-center justify-center p-4">
          <img src="/logo2.webp" className="w-[200px]" alt="Logo CCIA" />
        </div>
      </nav>
      <div className="h-screen w-full flex mt-40 justify-center">
        <form className="bg-white">
          <div className="w-[300px]">
            <h2 className="text-3xl mb-5 font-semibold">Iniciar Sesión</h2>
            {/* {error && <Error className="fadeIn pt-10" />} */}
            <div className="flex flex-col gap-y-4">
              <label className="flex flex-col w-full">
                Correo
                <input
                 className="border border-gray-400 rounded-lg px-4 py-2 w-full"
                  type="email"
                  name="email"
                  value={email}
                  required={true}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
              <label className="flex flex-col w-full">
                Contraseña
                <input
                 className="border border-gray-400 rounded-lg px-4 py-2 w-full"
                  type="password"
                  name="password"
                  value={password}
                  required={true}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
            </div>
            <button
              className="bg-green hover:bg-green-800 transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full"
              type="button"
              onClick={handleSubmit}
            >
              Iniciar sesión
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginForm;
