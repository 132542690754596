import { Route, Routes } from "react-router-dom";
import PerfilPage from "../components/Perfil";
import HomePage from "../components/Home";
import Areas from "../components/Areas";
import Dashboard from "../components/Dashboard";
import Equipos from "../components/Equipos";
import Usuarios from "../components/Usuarios";
import LoginForm from "../components/LoginForm";
import UserForm from "../components/UserForm";
import NotFound from "../components/NotFound";
import SearchResultPage from "../components/SearchedProducts";
import AnswersForm from "../components/AnswersForm";
import SoftwareForm from "../components/SoftwareForm";
import NewsForm from "../components/NewsForm";
import PublicityForm from "../components/PublicityForm";


const Views = () => {
  return (
    <Routes>
      <Route path="/" index element={<LoginForm />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/perfil" element={<PerfilPage />} />
      <Route path="/areas" element={<Areas />} >
        
        {/* <Route path="producto" >
        <Route path=":area" element={<ProductCardArea />} />
      </Route> */}
      </Route>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/categorias" element={<Equipos />} />
      <Route path="/usuarios" element={<Usuarios />} />
      <Route path="/formulario-noticias" element={<NewsForm />} />
      <Route path="/formulario-usuarios" element={<UserForm />} />
      <Route path="/busqueda/:term" element={<SearchResultPage/>} />
      <Route path="/noticias/:id/editar" element={<NewsForm />} />
      <Route path="/respuestas/crear" element={<AnswersForm />} />
      <Route path="/publicidad/crear" element={<PublicityForm />} />
      <Route path="/preguntas/crear" element={<SoftwareForm />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Views;
